<script>
import { userService } from "../../../helpers/backend/user.service";
export default {
  data() {
    return {
      form: {
        id: null,
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: true,
    };
  },
  computed: {
    isUpdateState() {
      return this.form.id != null;
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.busy = true;
      
      userService.changePassword(this.form).then(
        (data) => {
          if (data != null) {
            this.reset();
            this.$emit("refresh");
            this.showDismissibleAlert = true;
            this.error = "";
            // this.setActive(false);
          }
          this.busy = false;
        },
        (error) => {
          this.showDismissibleAlert = true;
          this.error =
            "Failed to Update Password. Error : " + error;
          this.busy = false;
        }
      );
    },
    reset() {
      // Reset our form values
      this.form.id = null;
      this.form.old_password = "";
      this.form.new_password = "";
      this.form.confirm_password = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.showDismissibleAlert = false;
      this.error = "";
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onReset(event) {
      event.preventDefault();
      this.setActive(false);
      this.reset();
    },
    // setActive(b, outside_triger) {
    //   this.active = b;
    //   if (outside_triger) return;
    //   this.$emit("active_change", b);
    // },
    setActive(b){
      this.active = b;
    },
    initForm(){
      this.setActive(true);
    },
    // initUpdate(id) {
    //   this.setActive(true);

    //   this.$nextTick(() => {
    //     this.reset();
    //     this.$refs.name.$el.focus();
    //     this.busy = true;
    //     userService.getById(id).then(
    //       (data) => {
    //         if (data.user != null) {
    //           this.form.id = data.user.id;
    //           this.form.name = data.user.name;
    //           this.form.user_number = data.user.user_number;
    //         } else {
    //           this.showDismissibleAlert = true;
    //           this.error =
    //             "Failed to get user type to update. Error : user not found";
    //         }
    //         this.busy = false;
    //       },
    //       (err) => {
    //         this.showDismissibleAlert = true;
    //         this.error = "Failed to get user type to update. Error : " + err;
    //         this.busy = false;
    //       }
    //     );
    //   });
    // },
    invokeCreate() {
      this.setActive(true);
    },
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Change Password action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                v-if="!isUpdateState"
                id="input-group-password"
                label="Old Password :"
                label-for="input-old-password"
              >
                <b-form-input
                  id="input-old-password"
                  v-model="form.old_password"
                  type="password"
                  placeholder="Insert your old password"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                v-if="!isUpdateState"
                id="input-group-password"
                label="New Password :"
                label-for="input-new-password"
              >
                <b-form-input
                  id="input-new-password"
                  v-model="form.new_password"
                  type="password"
                  placeholder="Insert new password"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                v-if="!isUpdateState"
                id="input-group-password"
                label="Confirm Password :"
                label-for="input-confirm-password"
              >
                <b-form-input
                  id="input-confirm-password"
                  v-model="form.confirm_password"
                  type="password"
                  placeholder="Insert new password for confirmation"
                  required
                ></b-form-input>
              </b-form-group>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div> -->
</template>



